<template src="./prescriptionDrugs.html"></template>

<script>
document.getElementsByClassName("vs-table--search-input").placeholder =
  "Pesquisar medicamento"

import { useStore } from "@/store/store.js"
import { computed, defineComponent } from "vue"
import mixins from "@/mixins/mixins.js"

export default defineComponent({
  mixins: [mixins],
  name: "prescription-drugs",
  data: () => ({
    userId: "",
    geolocation: false,
    cep: null,
    cepInvalid: false,
    cepValid: false,
    medicine: true,
    pharmacy: false,
    selected: [],
    drugs: [],
    drugsPaginated: [],
    drugsPages: 1,
    drugsCurrent: 1,
    address: null,
    lat: "",
    lng: "",
    errorStr: "",
    order: "ASC",
    order_distance: 250,
    show_input_cep: false,
    transitionName: "fade",
    pharmacies: [],
    bodyParameters: {},
    showResultsNotFoundAlert: false,
    cachePharmacies: [],
    selectCountOld: 0,
    optionsDistance: [
      { value: 10, text: "10 km" },
      { value: 20, text: "20 km" },
      { value: 30, text: "30 km" },
      { value: 40, text: "40 km" },
      { value: 50, text: "50 km" },
      { value: 60, text: "60 km" },
      { value: 70, text: "70 km" },
      { value: 80, text: "80 km" },
      { value: 90, text: "90 km" },
      { value: 250, text: "+ 100 km" },
    ],
  }),

  computed: {
    isSearchDisabled () {
      return !this.cep || this.cep.length < 9
    },
    isPaginated () {
      return this.drugs > 1
    },
    formattedAddress () {
      if (!this.address) {
        return null
      } else {
        console.log(this.address)
        if (typeof this.address === "string") {
          return this.address
        } else {
          const { street: s, neighborhood: n, city: c, state: t } = this.address
          return `${s}, ${n} - ${c} - ${t}, ${this.cep}`
        }
      }
    },
  },

  setup () {
    const store = useStore()

    return {
      isMobile: computed(() => store.getters.isMobile),
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },

  async mounted () {
    this.userId = this.$session.get("id")
    this.getMedicine()
    this.getAddress()
    this.getGeolocation()
  },
  methods: {
    getPosition(lat, lng) {
      return {
        lat: Number(lat),
        lng: Number(lng),
      };
    },
    googleMaps(lat, lng) {
      return {
        lat: Number(lat),
        lng: Number(lng),
      };
    },
    async searchAddress() {
      this.cepInvalid = false

      if (!this.isSearchDisabled) {
        const response = await this.getAddressByZipcode(this.cep)

        if (response && response.address) {
          let address = ""

          if (response.address.street) {
            address = response.address.street
          }

          if (response.address.neighborhood) {
            address += `${address ? ", " : ""}${response.address.neighborhood}`
          }

          address += `${address ? " - " : ""}
            ${response.address.city}/${response.address.state}`

          this.address = address.split(" ").filter(tx => tx).join(" ")

          this.cep = response.address.zipcode
        } else {
          this.address = null
          this.cepInvalid = true
  
          this.showNotifyWarning(
            `Não foi possível localizar o endereço através do CEP digitado.
            Digite um CEP válido e tente novamente.`,
            6000
          )
        }
      }
    },
    async getGeolocation() {
      const checkGeolocation = await navigator.permissions.query({ name: "geolocation" })
      
      if (checkGeolocation.state === "denied") {
        this.errorStr = "Geolocalização não esta habilitada."

        this.showNotifyWarning(
          `Para pesquisar medicamentos é necessário permitir a
          geolocalização. Habilite essa funcionalidade do seu navegador,
          recarregue a página e tente novamente.`,
          9500
        )
      } else {
        await navigator.geolocation.getCurrentPosition(
          async ({ coords }) => {
            this.$vs.loading()

            if (coords.latitude && coords.longitude) {
              const response = await this.getGoogleAddress({
                lat: coords.latitude,
                lng: coords.longitude,
              })

              if (response) {
                this.lat = response.geometry.location.lat
                this.lng = response.geometry.location.lng
                this.address = await response.formatted_address
                this.cep = await response.address_components
                  .find((addr) => (addr.types.includes("postal_code")))
                  .short_name
              }
            }

            this.$vs.loading.close()
          },
          (err) => (this.errorStr = err.message)
        )
      }
    },
    
    getMedicine() {
      this.$vs.loading()

      this.axios.get(
        `${this.$store.state.filooServer}orizon/${this.userId}/medicine`
      )
        .then(({ data }) => {
          this.drugs = data
          this.drugsPages = Math.ceil(this.drugs.length / 10)
          this.changePagination()
        })
        .catch((error) => console.error(error))
        .finally(() => this.$vs.loading.close())
    },

    getAddress() {
      this.$vs.loading()

      this.axios.get(
        `${this.$store.state.filooServer}orizon/address/${this.userId}`
      )
        .then(({ data }) => {
          this.address = data[0]
          this.cep = data[0].zipcode
          this.lat = data[0].latitude
          this.lng = data[0].longitude

          this.searchAddress()
        })
        .catch((error) => console.warn(error))
        .finally(() => this.$vs.loading.close())
    },

    getLatLng() {
      var vm = this;
      if (vm.cep) {
        vm.$vs.loading();
        vm.axios
          .get(
            "https://maps.googleapis.com/maps/api/geocode/json?address=" +
              vm.cep +
              "&key=AIzaSyAdwxi7P7SUDsbcxa3RRcQ315FDIOh1log"
          )
          .then((response) => {
            vm.lat = JSON.stringify(
              response["data"].results[0].geometry.location.lat
            );
            vm.lng = JSON.stringify(
              response["data"].results[0].geometry.location.lng
            );
            vm.getPharmacy();
          });
      } else {
        vm.getPharmacy();
      }
    },
    getPharmacy() {
      const vm = this;
      vm.pharmacy = true;
      vm.medicine = false;
      var lat;
      var lng;
      if (vm.cep != "") {
        lat = vm.lat;
        lng = vm.lng;
      } else {
        lat = vm.address.latitude;
        lng = vm.address.longitude;
      }
      vm.$vs.loading();
      vm.axios
        .post(
          `${vm.$store.state.filooServer}orizon/farmacia/`,
          (vm.bodyParameters = {
            medicamentos: vm.selected.map((s) =>
              vm.replaceSpecialChars(s.product)
            ),
            latitude: lat,
            longitude: lng,
          })
        )
        .then((response) => {
          var array = response.data;
          var result = [];

          for (var i = 0; i < array.length; i++) {
            array[i].distancia = array[i].distancia.replace(" km", "");
            result.push(array[i]);
          }

          vm.pharmacies = result.sort((a, b) => a.distancia - b.distancia);
          vm.cachePharmacies = vm.pharmacies;
          vm.showResultsNotFoundAlert = false;
        })
        .catch((error) => {
          vm.showResultsNotFoundAlert = true;
          console.log(`Deu erro: ${error}`);
        })
        .finally(() => {
          vm.$vs.loading.close();
        });
    },

    replaceSpecialChars(str) {
      if (!str) return "";
      str = str.toLowerCase();
      var mapaAcentosHex = {
        a: /[\xE0-\xE6]/g,
        e: /[\xE8-\xEB]/g,
        i: /[\xEC-\xEF]/g,
        o: /[\xF2-\xF6]/g,
        u: /[\xF9-\xFC]/g,
        c: /\xE7/g,
        n: /\xF1/g,
      };
      for (var letra in mapaAcentosHex) {
        var expressaoRegular = mapaAcentosHex[letra];
        str = str.replace(expressaoRegular, letra);
      }
      str = str.toUpperCase();
      return str;
    },
    sortPharmacy(index) {
      const vm = this;
      var pharma = vm.pharmacies[index];
      pharma.medicamentos.sort(
        function (a, b) {
          if (vm.order === "ASC") {
            return a.preco == b.preco ? 0 : a.preco > b.preco ? 1 : -1;
          } else if (vm.order === "DESC") {
            return a.preco == b.preco ? 0 : a.preco < b.preco ? 1 : -1;
          }
        }.bind(this)
      );
    },
    voltar() {
      const vm = this;
      vm.pharmacy = false;
      vm.medicine = true;
      vm.pharmacies = [];
    },
    handleSelected() {
      const vm = this;
      const selectedCount = vm.selected.length;
      if (vm.selected.length >= 5 && selectedCount > vm.selectCountOld) {
        vm.$vs.notify({
          time: 5000,
          title: "Atenção:",
          text: "Selecione até quatro medicamentos",
          color: "warning",
          position: "top-right",
        })
      }
      vm.selectCountOld = vm.selected.length;
    },
    changePagination() {
      const initialPage = Math.max(this.drugsCurrent - 1, 0)
      const endPage = initialPage + 1

      this.drugsPaginated = this.drugs.slice(initialPage * 10, endPage * 10)
      
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    order_distance: function (newVal) {
      const vm = this;
      if (newVal.value === 250) {
        vm.pharmacies = vm.cachePharmacies.filter(
          (p) => parseFloat(p.distancia) <= parseFloat(250)
        );
      } else {
        vm.pharmacies = vm.cachePharmacies.filter(
          (p) => parseFloat(p.distancia) <= parseFloat(newVal.value)
        );
      }
      if (vm.pharmacies.length <= 0) {
        vm.showResultsNotFoundAlert = true;
      } else {
        vm.showResultsNotFoundAlert = false;
      }
    },
    drugsCurrent () {
      this.changePagination()
    },
  },
})
</script>

<style lang="scss" src="./prescriptionDrugs.scss">
</style>